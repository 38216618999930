import React, { ReactElement, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { usePageTheme, useApp } from '@Context/AppContext';
import SEO from '@Components/SEO';
import ContentfulModule from '@Src/components/ContentfulModule';
import { IPageProps, IPageData, IBlock } from '@Types/ContentfulTypes';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import '@GraphQL/fragments';

export interface Props extends IPageProps {
	data: {
		contentfulPageTypeStandard: IPageData;
	};
}

const StandardPage = ({ data, location, transitionStatus, pageContext }: Props): ReactElement => {
	const { title, seoSettings, theme, content } = data.contentfulPageTypeStandard;
	const { setTheme: setPageTheme } = usePageTheme();
	const { pageType, setPageType, setPageTitle } = useApp();

	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

	useEffect(() => {
		if (pageType === 'index' && transitionStatus == 'exiting') {
			setLocalTransStatus(transitionStatus + 'Index');
		} else {
			setLocalTransStatus(transitionStatus);
		}

		if (transitionStatus == 'entering' || transitionStatus == 'entered') {
			setPageTheme('light');
			setPageTitle(title);
			setPageType(pageContext.type);
		}

		console.log('STANDARD:', transitionStatus);
	}, [transitionStatus]);

	return (
		<ContentLayout transitionState={localTransStatus}>
			<SEO
				location={location.pathname}
				title={seoSettings?.title || title}
				description={seoSettings?.description}
				meta={seoSettings?.metaTags}
				image={seoSettings?.image}
			/>
			{content &&
				content.map(
					(module: IBlock, index: number): ReactElement => (
						<ContentfulModule key={module.id} type={module.type} data={module} />
					)
				)}
		</ContentLayout>
	);
};

export default StandardPage;

export const query = graphql`
	query ($id: String!) {
		contentfulPageTypeStandard(id: { eq: $id }) {
			... on IPageNode {
				...PageFields
				content {
					... on IBlockNode {
						...AllBlocks
					}
				}
			}
		}
	}
`;
